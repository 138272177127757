<template>
  <table class="modern-table">
    <template v-for="property in relevantProperties">
      <tr v-if="!ignoredProperties.includes(camelCase(property))">
        <td>{{ camelCase(property) }}:</td>
        <td>{{ item[camelCase(property)] }}</td>
      </tr>
    </template>
  </table>
</template>

<script>
  import { localizationMixin } from '../../../mixins/localization';
  import { userMixin } from "../../../mixins/user";
  import { displayTime, numberToLocaleString } from "../../../helpers/itemHelpers";

  export default {
    mixins: [localizationMixin, userMixin],
    props: ['item', 'group'],
    data: () => ({
      ignoredProperties: [
        'description',
      ],
    }),
    computed: {
      relevantProperties() {
        return this.group.relevantItemProperties ? this.group.relevantItemProperties.split(',') : [];
      }
    },
    methods: {
      numberToLocaleString,
      displayTime,
      camelCase(string) {
        return string.charAt(0).toLowerCase() + string.substr(1,string.length - 1);
      }
    },
  }
</script>