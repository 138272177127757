<template>
  <div>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="selected-item__section-title selected-item__section-title--flex" id="ingredients">
      <span class="selected-item__section-title-text">
        {{ __('component.crafting_calculator.ingredients') }}
      </span>
      <span class="selected-item__section-title-action"
            :class="{'selected-item__section-title-action--flipped' : sorting === sortModes.desc}"
            @click="switchSorting()"
      >
        Sort
        <icon v-if="sorting !== sortModes.no" :icon="'triangle-up'"/>
        <icon v-if="sorting === sortModes.no" :icon="'list-point'"/>
      </span>
    </div>
    <div class="selected-item__group-filters" id="ingredients-groups">
      <div class="selected-item__group-filter"
           :title="`${__('showAll')}`"
           :class="{'selected-item__group-filter--active': ingredientGroupId === null}"
           @click="setIngredientGroupId(null)"
      >
        <div class="selected-item__group-filter-icon"
             :style="{'background-image': `url(https://cdn.hyperion-corporation.de/ui/svgs/asterisk.svg)`}"
        ></div>
      </div>
      <div class="selected-item__group-filter"
           v-for="group in ingredientGroups"
           :title="`${__('filterByGroup')}: ${group.name}`"
           :class="{'selected-item__group-filter--active': ingredientGroupId === group.id}"
           @click="setIngredientGroupId(group.id)"
      >
        <div class="selected-item__group-filter-icon"
             :style="{'background-image': `url(${group.icon})`}"
        ></div>
      </div>
    </div>
    <table class="editable-table editable-table--in-tile mt">
      <tr>
        <td></td>
        <td></td>
        <td class="text--right">{{__('A')}}</td>
        <td class="text--right">{{__('BC')}}</td>
        <td class="text--right">{{__('CPH')}}</td>
        <td class="text--right">{{__('TCT')}}</td>
        <td class="text--right">{{__('RIA')}}</td>
        <td class="text--center">{{__('configs')}}</td>
        <td class="text--right">{{__('RI')}}</td>
      </tr>
      <tr
          v-for="(ingredient, index) in filteredIngredients"
          class="editable-table__row"
          :id="`ingredient-${index}`"
      >
        <td class="editable-table__icon"><img :src="ingredient.item.icon" alt="-"/></td>
        <td>
          <div class="skewed-ribbon skewed-ribbon--absolute-right">
            <div class="skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">
              {{ ingredient.item.tier ? ingredient.item.tier : '-' }}
            </div>
          </div>
          <span v-html="renderWithTierAndSize(ingredient.item.name, ingredient.item.scale, ingredient.item.tier)"></span>
        </td>
        <td class="text--right" v-html="numberToLocaleString(ingredient.amount)"></td>
        <td class="text--right" v-html="numberToLocaleString(ingredient.amount / ingredient.item.minimumCraftingAmount)"></td>
        <td class="text--right" v-html="numberToLocaleString(ingredient.consumptionPerHour)"></td>
        <td class="text--right">{{ displayTime(ingredient.totalCraftingTime) }}</td>
        <td class="text--right" v-html="numberToLocaleString(ingredient.recommendedIndustriesAmount)"></td>
        <td style="width: 128px" :id="`ingredient-ingredient-configs-${index}`">
          <div class="single-select single-select--nowrap" :class="{'single-select--disabled': loading}">
            <div class="single-select__item"
                 :id="`ingredient-ingredient-configs-default-${index}`"
                 :class="getIngredientConfigurationStatus(ingredient, ingredientConfigurationTypes.none)"
                 :title="__('fullIngredientCalculationExplanation')"
                 @click="emitUpdateIngredientConfiguration(ingredient.item.id, ingredientConfigurationTypes.none)"
            >
              <icon class="single-select__item-icon" :icon="'flowchart'"/>
            </div>
            <div class="single-select__item"
                 :id="`ingredient-ingredient-configs-ignore-sub-${index}`"
                 :class="getIngredientConfigurationStatus(ingredient, ingredientConfigurationTypes.ignoreFollowUp)"
                 :title="__('ignoreFollowUpExplanation')"
                 @click="emitUpdateIngredientConfiguration(ingredient.item.id, ingredientConfigurationTypes.ignoreFollowUp)"
            >
              <icon class="single-select__item-icon" :icon="'clock'"/>
            </div>
            <div class="single-select__item"
                 :id="`ingredient-ingredient-configs-ignore-${index}`"
                 :class="getIngredientConfigurationStatus(ingredient, ingredientConfigurationTypes.ignoreCraftingTime)"
                 :title="__('ignoreFollowUpAndCraftingTimeExplanation')"
                 @click="emitUpdateIngredientConfiguration(ingredient.item.id, ingredientConfigurationTypes.ignoreCraftingTime)"
            >
              <icon class="single-select__item-icon" :icon="'list-point'"/>
            </div>
          </div>
        </td>
        <td>
          <div class="selected-item__required-industries"
               :id="`ingredient-ingredient-industries-${index}`">
            <div class="selected-item__required-industry selected-item__required-industry--small"
                 v-for="requiredIndustry in ingredient.requiredIndustries"
                 :style="{'background-image': `url(${requiredIndustry.icon})`}"
                 :title="requiredIndustry.fullName"
                 :class="getTierBackgroundClass(requiredIndustry)"
            >
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { localizationMixin } from '../../mixins/localization';
import apiSecured from "../../api/secured";
import { notificationsMixin } from "../../mixins/notifications";
import { userMixin } from "../../mixins/user";
import { displayTime, numberToLocaleString, renderWithTierAndSize } from "../../helpers/itemHelpers";

const tourStepParams = {
  placement: 'bottom',
  highlight: true,
  enableScrolling: false
};

export default {
  mixins: [localizationMixin, notificationsMixin, userMixin],
  props: ['ingredients', 'ingredientConfigurations', 'ingredientConfigurationTypes'],
  data: () => ({
    loading: false,
    ingredientGroups: [],
    ingredientGroupId: null,
    sortModes: {
      no: 'no',
      asc: 'asc',
      desc: 'desc',
    },
    sorting: 'no',
  }),
  watch: {
    ingredients() {
      this.refreshIngredientGroups();
    },
  },
  computed: {
    filteredIngredients() {
      let ingredients = [];
      if (this.ingredientGroupId === null) {
        ingredients = Object.keys(this.ingredients).map(key => this.ingredients[key]);
      } else {
        ingredients = Object.keys(this.ingredients).map(key => this.ingredients[key]).filter(ingredient => ingredient.item.groupId === this.ingredientGroupId);
      }

      if (this.sorting !== this.sortModes.no) {
        let sortTier = (a, b) => a.item.tier - b.item.tier;
        let sortGroup = (a, b) => a.group && b.group ? a.group.sorting - b.group.sorting : 0;

        if (this.sorting === this.sortModes.desc) {
          sortTier = (a, b) => b.item.tier - a.item.tier;
          sortGroup = (a, b) => b.group && a.group ? b.group.sorting - a.group.sorting : 0;
        }

        if (this.ingredientGroups.length > 0) {
          ingredients = ingredients.map(ingredient => {
            ingredient.group = this.ingredientGroups.find(group => group.id === ingredient.item.groupId);
            return ingredient;
          })
          ingredients = ingredients.sort(sortTier);
        }
        ingredients.sort(sortGroup);
      }
      return ingredients;
    },
  },
  methods: {
    numberToLocaleString,
    renderWithTierAndSize,
    displayTime,
    getTierBackgroundClass(item) {
      return {
        'selected-item__required-industry--tier-1': item.tier === 1,
        'selected-item__required-industry--tier-2': item.tier === 2,
        'selected-item__required-industry--tier-3': item.tier === 3,
        'selected-item__required-industry--tier-4': item.tier === 4,
        'selected-item__required-industry--tier-5': item.tier === 5,
      }
    },
    switchSorting() {
      switch (this.sorting) {
        case this.sortModes.no:
          this.sorting = this.sortModes.asc;
          break;

        case this.sortModes.asc:
          this.sorting = this.sortModes.desc;
          break;

        default:
          this.sorting = this.sortModes.no;
      }
      localStorage.setItem('ingredientsSorting', this.sorting);
    },
    setIngredientGroupId(id) {
      this.ingredientGroupId = id;
    },
    getIngredientConfigurationStatus(ingredient, target) {
      let selectedIngredientConfigurations = this.ingredientConfigurations.filter(ic => ic.itemId === ingredient.item.id);
      let selected = false;

      selected = selectedIngredientConfigurations.length === 0 && target === this.ingredientConfigurationTypes.none;

      if (selectedIngredientConfigurations.length > 0) {
        selected = selectedIngredientConfigurations.filter(ic => ic.type === target).length > 0;
      }

      return {
        'single-select__item--selected': selected
      }
    },
    async refreshIngredientGroups() {
      this.ingredientGroups = [];
      const groupIds = Object.keys(this.ingredients).map(id => this.ingredients[id].item.groupId);
      if (groupIds.length === 0) {
        return;
      }
      this.loading = true;
      await apiSecured.get(`/ingame/items/group/(${[...new Set(groupIds)].join(',')})`)
          .then((res) => {
            this.ingredientGroups = res.data.sort((a, b) => a.sorting - b.sorting);
            this.loading = false;
            this.showInfoNotification('Ingredient groups loaded');
            this.$emit('ingredientGroupsLoaded', this.ingredientGroups);
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    emitUpdateIngredientConfiguration(id, type) {
      this.$emit('updateIngredientConfiguration', {id, type})
    }
  },
  created() {
    const savedSorting = localStorage.getItem('ingredientsSorting');
    if (Object.values(this.sortModes).includes(savedSorting)) {
      this.sorting = savedSorting;
    }
  }
}
</script>