<template>
  <div class="view">
    <progress-linear v-if="loading" :indeterminate="true"/>
    <h2></h2>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          v-if="pagination.pages > 1"
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <div class="crafting-lists mt">
        <div class="crafting-lists__item" v-for="craftingList in craftingLists">
          <div class="crafting-lists__item-name" @click="handleCraftingListSelected(craftingList.id)">{{
            craftingList.name }}
          </div>
          <div class="crafting-lists__item-action" @click="deleteRecord(craftingList)">
            <icon :icon="'bin'"/>
          </div>
        </div>
        <div class="btn btn--info crafting-lists__button" @click="handleCreateRequested">{{ __('createACraftingList')
          }}
        </div>
      </div>
    </div>
    <pagination
        v-if="pagination.pages > 1"
        class="mt"
        :count-pages="pagination.pages"
        :total-visible="10"
        :page="pagination.page"
        :disabled="loading"
        @paginate="paginate"
    />
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin],
    components: {},
    data: () => ({
      loading: false,
      craftingLists: [],
      skipCreated: false,
    }),
    methods: {
      handleCraftingListSelected(id) {
        this.$emit('selected', id);
      },
      handleCreateRequested() {
        this.$emit('create');
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        params.userIds = this.$store.state.user.id;
        apiSecured.get('/ingame/items/craftinglist', { params }).then(async (res) => {
          this.craftingLists = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification('Crafting Lists loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {

      },
      addItem() {
        this.$emit('createRequested');
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/items/craftinglist/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.$emit('deleted');
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      this.refresh();
    }
  }
</script>
