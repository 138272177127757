<template>
    <div class="crafting-tree">
      <div class="crafting-tree__header-switch" @click="toggleShowOptions()">
        <div class="crafting-tree__header-switch-line"></div>
        <div class="crafting-tree__header-switch-label">{{__(showOptions ? 'hideOptions' : 'showOptions')}}</div>
        <div class="crafting-tree__header-switch-line"></div>
      </div>
      <div class="crafting-tree__header" :class="{'crafting-tree__header--shown': showOptions}">
        <div class="layout layout--wrap">
          <div class="flex xs12 sm6 md4">
            <table class="data-table">
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('A')}}</td>
                <td class="data-table__cell">{{ __('amount') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('TCT')}}</td>
                <td class="data-table__cell">{{ __('tctLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('TLCT')}}</td>
                <td class="data-table__cell">{{ __('tlctLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('RCT')}}</td>
                <td class="data-table__cell">{{ __('rctLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('RLCT')}}</td>
                <td class="data-table__cell">{{ __('rlctLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('BS')}}</td>
                <td class="data-table__cell">{{ __('bsLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('OPH')}}</td>
                <td class="data-table__cell">{{ __('ophLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('OPH')}}</td>
                <td class="data-table__cell">{{ __('mophLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('CPH')}}</td>
                <td class="data-table__cell">{{ __('cphLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('RI')}}</td>
                <td class="data-table__cell">{{ __('riLegend') }}</td>
              </tr>
              <tr class="data-table__row">
                <td class="data-table__cell">{{__('RIA')}}</td>
                <td class="data-table__cell">{{ __('riaLegend') }}</td>
              </tr>
            </table>
          </div>
          <div class="flex xs12 sm6 md8">
            <div class="layout layout--wrap">
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showName"
                    :label="__('showName')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showIcon"
                    :label="__('showIcon')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showAmount"
                    :label="__('showAmount')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showTCT"
                    :label="__('showTCT')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showRCT"
                    :label="__('showRCT')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showTLCT"
                    :label="__('showTLCT')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showRLCT"
                    :label="__('showRLCT')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showBS"
                    :label="__('showBS')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showOPH"
                    :label="__('showOPH')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showMOPH"
                    :label="__('showMOPH')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showCPH"
                    :label="__('showCPH')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showRI"
                    :label="__('showRI')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <my-switch
                    v-model="showRIA"
                    :label="__('showRIA')"
                ></my-switch>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <div class="btn btn--info" @click="expandTree">{{__('expandTree')}}</div>
              </div>
              <div class="flex xs12 sm6 md3 mt">
                <div class="btn btn--info" @click="collapseTree">{{__('collapseTree')}}</div>
              </div>
              <div class="flex xs12 mt text--large" v-html="__('hintHorizontalScroll')"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="crafting-tree__nodes">
        <crafting-tree-node
            :ref="'rootNode'"
            :node="tree"
            :node-id="nodeId"
            :show-name="showName"
            :show-icon="showIcon"
            :show-amount="showAmount"
            :show-t-c-t="showTCT"
            :show-t-l-c-t="showTLCT"
            :show-r-c-t="showRCT"
            :show-r-l-c-t="showRLCT"
            :show-b-s="showBS"
            :show-o-p-h="showOPH"
            :show-m-o-p-h="showMOPH"
            :show-c-p-h="showCPH"
            :show-r-i="showRI"
            :show-r-i-a="showRIA"
            :force-expanded="true"
            @nodeSelected="emitNodeClicked"
        />
      </div>
    </div>
</template>

<script>
import CraftingTreeNode from "./CraftingTreeNode";
import { localizationMixin } from "../../mixins/localization";

export default {
  mixins: [localizationMixin],
  components: { CraftingTreeNode },
  props: ['tree', 'nodeId'],
  data: () => ({
    showName: true,
    showIcon: true,
    showAmount: true,
    showTCT: true,
    showTLCT: true,
    showRCT: true,
    showRLCT: true,
    showBS: true,
    showOPH: true,
    showMOPH: true,
    showCPH: true,
    showRI: true,
    showRIA: true,

    showOptions: true,
  }),
  methods: {
    emitNodeClicked({ nodeId, itemId }) {
      this.$emit('nodeSelected',{nodeId, itemId});
    },
    toggleShowOptions() {
      this.showOptions = !this.showOptions;
      localStorage.setItem('showCraftingTreeOptions', this.showOptions ? "1" : "0");
    },
    expandTree() {
      this.$refs.rootNode.setExpanded(true);
    },
    collapseTree() {
      this.$refs.rootNode.setExpanded(false);
    }
  },
  created() {
    const storedShowOptions = localStorage.getItem('showCraftingTreeOptions');
    if(["0", "1"].includes(storedShowOptions)) {
      this.showOptions = storedShowOptions === "1";
    }
  }
}
</script>

<style scoped>

</style>