<template>
  <div class="crafting-calculator view">
    <app-header :title="__('view.ingame.crafting_calculator.title')"/>
    <div class="multipage-wrapper">
      <div class="multipage multipage--left"
           :class="{'multipage--active': selectedItemId === null && craftingListId === null}">
        <div class="layout">
          <div class="flex xs12 sm4" :class="{'condensed': !expandCraftingLists}">
            <crafting-lists ref="craftingLists"
                            @selected="handleCraftingListIdChanged"
                            @create="handleCraftingListIdChanged('new')"
                            @deleted="craftingListId = null"
            />
            <div class="expand-button" @click="toggleExpandCraftingLists">
              <icon :icon="'chevron-left'"/>
            </div>
          </div>
          <div class="flex xs12 view" :class="{'sm8': expandCraftingLists, 'sm12': !expandCraftingLists}">
            <progress-linear v-if="loading" :indeterminate="true"/>
            <div class="control-bar" :class="{'control-bar--disabled': loading}">
              <my-switch
                  v-if="hasOneRoles(['InGame Items Editor','Member'])"
                  class="mr"
                  v-model="showHidden"
                  :label="__('common.show_hidden')"
                  @input="refreshItems"
              ></my-switch>
              <text-field
                  class="control-bar__search"
                  v-model="searchTerm"
                  :label="__('common.search_term')"
                  max-length="50"
                  :hide-details="true"
                  @keyup:enter="filterBySearchTerm"
              />
              <div class="control-bar__buttons">
                <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
                  <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
                       alt="refresh"/>
                </div>
              </div>
            </div>
            <div class="layout">
              <div class="flex xs3 mt">
                <groups-tree ref="groupsNav" @groupSelected="filterByGroup"/>
              </div>
              <div class="flex xs9 mt">
                <item-list :items="items" @itemSelected="handleItemSelected" @edit="editItem"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="multipage multipage--right" :class="{'multipage--active': selectedItemId !== null}">
        <selected-item
            :selected-item-id="selectedItemId"
            @goBack="selectedItemId = null"
            @edit="editItem"
            @itemLoaded="updateRouteAndTitle"
        />
      </div>
      <div class="multipage multipage--right-2" :class="{'multipage--active': craftingListId !== null}">
        <selected-crafting-list
            :crafting-list-id="craftingListId"
            @goBack="craftingListId = null"
            @edit="editItem"
            @craftingListLoaded="updateRouteAndTitle"
            @craftingListIdChanged="handleCraftingListIdChanged"
            @created="handleCraftingListCreated"
            @delete="deleteCraftingList"
        />
      </div>
    </div>
    <edit-item ref="editItem" @updated="refresh" @created="editItem"/>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import AppHeader from '../../components/AppHeader';
  import GroupsTree from '../../components/CraftingCalculator/GroupsTreeNav';
  import ItemList from '../../components/CraftingCalculator/ItemList';
  import SelectedItem from '../../components/CraftingCalculator/SelectedItem';
  import EditItem from '../../components/Ingame/EditItem';
  import SelectedCraftingList from "../../components/CraftingCalculator/SelectedCraftingList";
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { pageTitleMixin } from '../../mixins/pageTitle';
  import { userMixin } from '../../mixins/user';
  import CraftingLists from "../../components/CraftingCalculator/CraftingLists";

  export default {
    mixins: [notificationsMixin, localizationMixin, pageTitleMixin, userMixin],
    components: {
      CraftingLists,
      AppHeader,
      GroupsTree,
      ItemList,
      SelectedItem,
      EditItem,
      SelectedCraftingList
    },
    data: () => ({
      loading: false,
      groups: [],
      items: [],
      searchTerm: '',
      groupIds: [],
      filterMode: 'searchTerm',
      selectedItemId: null,
      craftingListId: null,
      showHidden: false,
      expandCraftingLists: true,
    }),
    watch: {
      selectedItemId() {
        this.updateRouteAndTitle();
      },
      craftingListId() {
        this.updateRouteAndTitle();
      },
    },
    methods: {
      deleteCraftingList(craftingList) {
        this.$refs.craftingLists.deleteRecord(craftingList);
      },
      toggleExpandCraftingLists() {
        this.expandCraftingLists = !this.expandCraftingLists;
        localStorage.setItem('expandCraftingLists', this.expandCraftingLists ? '1' : '0');
      },
      handleCraftingListCreated() {
        this.$refs.craftingLists.refresh();
      },
      updateRouteAndTitle(subject) {
        if (this.selectedItemId !== null) {
          this.$router.push({
            name: this.$route.name,
            query: {
              selectedItemId: this.selectedItemId,
            }
          }).catch(err => {
          });
          if (subject === undefined) {
            subject = this.items.find(item => item.id === this.selectedItemId);
          }
          if (subject !== undefined) {
            this.setPageTitle(subject.name + ' / ' + this.__('common.crafting_calculator'));
          }
        } else if (this.craftingListId !== null) {
          this.$router.push({
            name: this.$route.name,
            query: {
              craftingListId: this.craftingListId,
            }
          }).catch(err => {
          });
          if (subject !== undefined) {
            this.setPageTitle(subject.name + ' / ' + this.__('common.crafting_calculator'));
          }
        } else {
          this.$router.push({
            name: this.$route.name
          }).catch(err => {
          })
          this.setPageTitle(this.__('common.crafting_calculator'));
        }
      },
      editItem(item) {
        this.$refs.editItem.showEdit(item);
      },
      handleItemSelected({ id }) {
        this.selectedItemId = id;
      },
      handleCraftingListIdChanged(id) {
        this.craftingListId = id;
      },
      filterByGroup(group) {
        this.searchTerm = '';
        this.groupIds = [group.id];
        this.filterMode = 'groupIds';
        this.refreshItems();
      },
      filterBySearchTerm() {
        this.groupIds = [];
        this.$refs.groupsNav.deselectGroup();
        this.filterMode = 'searchTerm';
        this.refreshItems();
      },
      async refresh() {
        await this.refreshItems();
      },
      async refreshItems() {
        const params = {
          pageSize: 10000,
          searchQuery: '',
          groupIds: '',
          fields: 'id,name,scale,fullName,groupId,icon,hidden,tier,needReview',
          orderBy: 'name',
          showHidden: this.showHidden,
          recursiveGroupsFilter: true
        };

        if (this.filterMode === 'searchTerm') {
          if (this.searchTerm.length < 3) {
            return;
          }
          params.searchQuery = this.searchTerm;
        } else if (this.filterMode === 'groupIds') {
          params.groupIds = this.groupIds.join(',');
        } else {
          return;
        }

        this.loading = true;
        await apiSecured.get('/ingame/items/item', { params })
          .then((res) => {
            this.items = res.data;
            this.loading = false;
            this.showInfoNotification('Items loaded');
            this.updateRouteAndTitle();
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
      },
    },
    created() {
      const expandCraftingLists = localStorage.getItem('expandCraftingLists');
      if(expandCraftingLists === '1') {
        this.expandCraftingLists = true;
      } else if (expandCraftingLists === '0') {
        this.expandCraftingLists = false;
      }
      this.setPageTitle(this.__('common.crafting_calculator'));
      this.refresh();
      if (this.$route.query.selectedItemId) {
        this.selectedItemId = this.$route.query.selectedItemId;
      }
      if (this.$route.query.craftingListId) {
        this.craftingListId = this.$route.query.craftingListId;
      }
    },
    beforeRouteUpdate (to, from, next) {
      if (to.query.selectedItemId) {
        this.selectedItemId = to.query.selectedItemId;
      } else if (to.query.craftingListId) {
        this.craftingListId = to.query.craftingListId;
      } else {
        this.selectedItemId = null;
        this.craftingListId = null;
      }
      next();
    }
  }
</script>