<template>
  <div class="crafting-tree__node-wrapper" v-if="node">
    <div class="crafting-tree__node"
         :class="classes"
         :title="node.item.fullName"
         @click="emitNodeClicked({nodeId: node.id, itemId: node.item.id})"
         v-slashes
    >
      <div class="crafting-tree__node-content">
        <div class="crafting-tree__node-icon" v-if="showIcon && node.item.icon"><img :src="node.item.icon"></div>
        <div class="crafting-tree__node-cell crafting-tree__node-cell--big" v-if="showName"
             v-html="renderWithTierAndSize(node.item.name, node.item.scale, node.item.tier)"></div>
        <div class="crafting-tree__node-row">
          <div class="crafting-tree__node-label" v-if="showAmount">{{ __('A') }}</div>
          <div class="crafting-tree__node-cell" v-if="showAmount" v-html="numberToLocaleString(node.amount)"></div>

          <div class="crafting-tree__node-label" v-if="showTCT">{{ __('TCT') }}</div>
          <div class="crafting-tree__node-cell" v-if="showTCT" v-html="displayTime(node.totalCraftingTime)"></div>

          <div class="crafting-tree__node-label" v-if="showTLCT">{{ __('TLCT') }}</div>
          <div class="crafting-tree__node-cell" v-if="showTLCT" v-html="displayTime(node.totalLinearCraftingTime)"></div>

          <div class="crafting-tree__node-label" v-if="showRCT">{{ __('RCT') }}</div>
          <div class="crafting-tree__node-cell" v-if="showRCT" v-html="displayTime(node.recursiveCraftingTime)"></div>

          <div class="crafting-tree__node-label" v-if="showRLCT">{{ __('RLCT') }}</div>
          <div class="crafting-tree__node-cell" v-if="showRLCT" v-html="displayTime(node.recursiveLinearCraftingTime)"></div>

          <div class="crafting-tree__node-label" v-if="showBS">{{ __('BS') }}</div>
          <div class="crafting-tree__node-cell" v-if="showBS"
               v-html="`${numberToLocaleString(node.item.minimumCraftingAmount,0,0)} / ${numberToLocaleString(node.amount / node.item.minimumCraftingAmount)}`"></div>

          <div class="crafting-tree__node-label" v-if="showOPH">{{ __('OPH') }}</div>
          <div class="crafting-tree__node-cell" v-if="showOPH" v-html="numberToLocaleString(node.outputPerHour)"></div>

          <div class="crafting-tree__node-label" v-if="showMOPH">{{ __('MOPH') }}</div>
          <div class="crafting-tree__node-cell" v-if="showMOPH" v-html="numberToLocaleString(node.maxOutputPerHour)"></div>

          <div class="crafting-tree__node-label" v-if="showCPH && parent">{{ __('CPH') }}</div>
          <div class="crafting-tree__node-cell" v-if="showCPH && parent" v-html="numberToLocaleString(node.consumptionPerHour)"></div>

          <div class="crafting-tree__node-label" v-if="showRIA">{{ __('RIA') }}</div>
          <div class="crafting-tree__node-cell" v-if="showRIA" v-html="numberToLocaleString(node.recommendedIndustriesAmount)"></div>

          <div class="crafting-tree__node-label" v-if="showRI">{{ __('RI') }}</div>
          <div class="crafting-tree__node-cell" v-if="showRI">
            <img v-for="requiredIndustry in node.requiredIndustries" :src="requiredIndustry.icon"
                 :title="requiredIndustry.fullName" :class="getTierBackgroundClass(requiredIndustry)"/>
          </div>
        </div>
      </div>
      <div class="crafting-tree__node-expand"
           :class="{'crafting-tree__node-expand--expanded': forceExpanded || expanded}"
           v-if="!forceExpanded && node.children && node.children.length > 0"
           @click="toggleExpanded"
      >
        <icon :icon="'chevron-left'" :block="true"></icon>
      </div>
    </div>
    <div class="crafting-tree__node-children" v-if="expanded || forceExpanded">
      <crafting-tree-node
          v-for="child in node.children"
          :ref="'childNodes'"
          :node="child"
          :parent="node"
          :key="`node_${child.item.id}`"
          :node-id="nodeId"
          :active="active ? active : node.id === nodeId"
          :show-name="showName"
          :show-icon="showIcon"
          :show-amount="showAmount"
          :show-t-c-t="showTCT"
          :show-r-c-t="showRLCT"
          :show-t-l-c-t="showTCT"
          :show-r-l-c-t="showRLCT"
          :show-b-s="showBS"
          :show-o-p-h="showOPH"
          :show-m-o-p-h="showMOPH"
          :show-c-p-h="showCPH"
          :show-r-i="showRI"
          :show-r-i-a="showRIA"
          @nodeSelected="emitNodeClicked"
      />
    </div>
  </div>
</template>

<script>
import { displayTime, numberToLocaleString, renderWithTierAndSize } from "../../helpers/itemHelpers";
import CraftingTreeNode from "./CraftingTreeNode";
import { localizationMixin } from "../../mixins/localization";

export default {
  name: "CraftingTreeNode",
  components: { CraftingTreeNode },
  mixins: [localizationMixin],
  props: {
    node: {
      default: null,
    },
    parent: {
      default: null,
    },
    nodeId: {
      default: null,
    },
    active: {
      default: false,
    },
    showName: {
      default: true,
    },
    showIcon: {
      default: true,
    },
    showAmount: {
      default: true,
    },
    showTCT: {
      default: true,
    },
    showRCT: {
      default: true,
    },
    showTLCT: {
      default: true,
    },
    showRLCT: {
      default: true,
    },
    showBS: {
      default: true,
    },
    showOPH: {
      default: true,
    },
    showMOPH: {
      default: true,
    },
    showCPH: {
      default: true,
    },
    showRI: {
      default: true,
    },
    showRIA: {
      default: true,
    },
    forceExpanded: {
      default: false,
    }
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    classes() {
      return {
        'crafting-tree__node--selected': this.node.id === this.nodeId,
        'crafting-tree__node--active': this.active,
      }
    }
  },
  methods: {
    numberToLocaleString,
    renderWithTierAndSize,
    displayTime,
    getTierBackgroundClass(item) {
      return {
        'background--tier-1': item.tier === 1,
        'background--tier-2': item.tier === 2,
        'background--tier-3': item.tier === 3,
        'background--tier-4': item.tier === 4,
        'background--tier-5': item.tier === 5,
      }
    },
    emitNodeClicked({ nodeId, itemId }) {
      this.$emit('nodeSelected', { nodeId, itemId });
    },
    toggleExpanded(event) {
      event.stopPropagation();
      this.expanded = !this.expanded;
    },
    setExpanded(value) {
      this.expanded = value;

      this.$nextTick(() => {

        const children = this.$refs.childNodes;

        if(children && children.length > 0) {

          children.forEach(child => {
            try {
              child.setExpanded(value)
            } catch {
              // do nothing here
            }
          });

        }

      })
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>