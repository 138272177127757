<template>
  <div class="selected-item view">
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__buttons">
        <div class="control-bar__button"
             :title="__('component.files.file_browser.navigate_up')"
             @click="goBack"
             v-slashes
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/chevron-left.svg"
               alt="back"/>
        </div>
        <div class="control-bar__button" :title="__('reportItem')" @click="showReportItem" v-slashes id="report">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/report.svg"
               alt="report"/>
        </div>
        <div class="control-bar__button" :title="'Share'" @click="copyUri" v-slashes id="share">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/share.svg"
               alt="share"/>
        </div>
      </div>
    </div>
    <div class="layout layout--wrap">
      <div class="flex xs12 sm3 mt">
        <div class="tile" id="basic-info">
          <edit-crafting-list
              v-if="(hasEditRights && showEditCraftingList) || createMode"
              ref="editCraftingList"
              :crafting-list-id="createMode ? null : this.craftingListId"
              @created="handleCraftingListCreated"
              @updated="refreshCraftingList"
              @closed="showEditCraftingList = false"
          />
          <div class="layout layout--wrap" v-else>
            <div class="flex xs12 mt">
              <div class="selected-item__title selected-item__title--flex">
                <span class="selected-item__title-text">{{ craftingList ? craftingList.name : '-' }}</span>
                <span class="selected-item__title-action" v-if="hasEditRights" @click="showEditCraftingList = true"><icon
                    :icon="'pencil'"/></span>
                <span class="selected-item__title-action" v-if="hasEditRights" @click="deleteCraftingList"><icon
                    :icon="'bin'"/></span>
              </div>
            </div>
            <div class="flex xs12">
              <div class="selected-item__description mt bodytext"
                   v-html="craftingList ? craftingList.description : '<p>---</p>'"></div>
            </div>
            <div class="flex xs12">
              <div class="selected-item__owner" v-if="owner">
                <div class="selected-item__owner-avatar"
                     :style="{backgroundImage: `url(${owner.avatar ? owner.avatar : 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png'})`}"></div>
                <div class="selected-item__owner-name">{{ owner.userName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex xs12 sm3 mt">
        <div class="tile">
          <template v-if="!createMode">
            <div class="selected-item__title">{{ __('totals') }}</div>
            <div class="layout layout--wrap mt">
              <div class="flex xs12 sm6 selected-item__primary-result-label">
                {{ __('craftingTime') }}
              </div>
              <div class="flex xs12 sm6 selected-item__primary-result-value">{{ displayTime(craftingTime) }}</div>
            </div>
            <div class="layout layout--wrap mt">
              <div class="flex xs12 sm6 selected-item__primary-result-label">
                {{ __('oreBuyValue') }}
              </div>
              <div class="flex xs12 sm6 selected-item__primary-result-value"
                   v-html="numberToLocaleString(recursiveOreBuyPrice)">
              </div>
            </div>
            <div class="layout layout--wrap mt">
              <div class="flex xs12 sm6 selected-item__primary-result-label">
                {{ __('oreSellValue') }}
              </div>
              <div class="flex xs12 sm6 selected-item__primary-result-value"
                   v-html="numberToLocaleString(recursiveOreSellPrice)">
              </div>
            </div>
            <div class="layout layout--wrap mt" id="required-industries">
              <div class="flex xs12 sm6 selected-item__primary-result-label">
                {{ __('mass') }}
              </div>
              <div class="flex xs12 sm6 selected-item__primary-result-value"
                   v-html="endProducts.length > 0 ? numberToLocaleString(endProducts.map(endProduct => endProduct.item.mass * endProduct.amount).reduce((current,accumulator) => current+accumulator)) + ' kg' : '-'"
              >
              </div>
            </div>
            <div class="layout layout--wrap mt">
              <div class="flex xs12 mt">
                <text-field
                    v-model="amount"
                    :label="__('component.crafting_calculator.amount')"
                    :min="1"
                    :type="'number'"
                    :readonly="loading"
                    :hide-details="true"
                    @keyup:enter="refresh"
                />
              </div>
              <div class="flex xs12 mt">
                <div class="btn btn--info btn--big" :loading="loading" :disabled="loading" id="calculate"
                     @click="refresh" v-slashes>{{ __('component.crafting_calculator.calculate') }}
                </div>
              </div>
              <div class="flex xs12 mt">
                <my-switch
                    id="ignore-ingredient-configs"
                    v-model="ignoreIngredientConfigurations"
                    :label="__('ignoreIngredientConfigurations')"
                    :disabled="loading"
                    @input="refresh"
                ></my-switch>
              </div>
              <div class="flex xs12 mt">
                <my-switch
                    v-model="showTree"
                    :label="__('showCraftingTree')"
                    :disabled="loading"
                    @input="toggleShowTree"
                ></my-switch>
              </div>
            </div>
          </template>
          <h2 v-else>{{ __('createCraftingListFirst') }}</h2>
        </div>
      </div>
      <div class="flex xs12 sm6 mt">
        <div class="tile" id="price-logs">
          <price-logs :item-id="null"/>
        </div>
      </div>

      <div class="flex xs12 mt" v-if="showTree">
        <crafting-tree :tree="tree" :node-id="selectedNodeId" @nodeSelected="handleNodeClicked"/>
      </div>

      <div class="flex xs12 sm7 mt">
        <div class="tile">
          <div class="selected-item__section-title">{{ __('endProducts') }}</div>
          <template v-if="!createMode">
            <table class="editable-table editable-table--in-tile mt">
              <tr
                  v-for="(endProduct, index) in endProducts"
                  class="editable-table__row"
                  :id="`end-product-${index}`"
              >
                <td class="editable-table__icon"
                    :id="`end-product-icon-${index}`"><img :src="endProduct.item.icon" alt="-"/></td>
                <td :id="`end-product-name-${index}`">
                  <div class="skewed-ribbon skewed-ribbon--absolute-right" :id="`end-product-tier-${index}`">
                    <div class="skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">
                      {{ endProduct.item.tier ? endProduct.item.tier : '-' }}
                    </div>
                  </div>
                  <span v-html="renderWithTierAndSize(endProduct.item.name, endProduct.item.scale, endProduct.item.tier)"></span>
                </td>
                <td class="text--right" :id="`end-product-amount-${index}`">
                  <template v-if="hasEditRights">
                    <text-field
                        v-model="endProduct.actualAmount"
                        :label="__('component.crafting_calculator.amount')"
                        :min="1"
                        :type="'number'"
                        :readonly="loading"
                        :prepend-icon="isChangedEndProductAmount(endProduct) ? 'pencil': null"
                        :hide-details="true"
                        @input="handleEndProductAmountChanged(endProduct)"
                        @keyup:enter="updateEndProductAmount(endProduct)"
                        @blur="updateEndProductAmount(endProduct)"
                    />
                  </template>
                  <template v-else>
                    <div v-html="numberToLocaleString(endProduct.amount)"></div>
                  </template>
                </td>
                <td class="text--right">{{endProduct.amount}}</td>
                <td class="text--right" v-html="`${numberToLocaleString(endProduct.amount * endProduct.item.mass, 0, 0)} kg`"></td>
                <td class="text--right" :id="`end-product-crafting-time-${index}`">{{
                    displayTime(endProduct.recursiveCraftingTime)
                  }}
                </td>
                <td style="width: 128px" :id="`end-product-ingredient-configs-${index}`">
                  <div class="single-select single-select--nowrap" :class="{'single-select--disabled': loading}">
                    <div class="single-select__item"
                         :id="`end-product-ingredient-configs-default-${index}`"
                         :class="getIngredientConfigurationStatus(endProduct.item.id, ingredientConfigurationTypes.none)"
                         :title="__('fullIngredientCalculationExplanation')"
                         @click="updateIngredientConfiguration({id: endProduct.item.id, type: ingredientConfigurationTypes.none})"
                    >
                      <icon class="single-select__item-icon" :icon="'flowchart'"/>
                    </div>
                    <div class="single-select__item"
                         :id="`end-product-ingredient-configs-ignore-sub-${index}`"
                         :class="getIngredientConfigurationStatus(endProduct.item.id, ingredientConfigurationTypes.ignoreFollowUp)"
                         :title="__('ignoreFollowUpExplanation')"
                         @click="updateIngredientConfiguration({id: endProduct.item.id, type: ingredientConfigurationTypes.ignoreFollowUp})"
                    >
                      <icon class="single-select__item-icon" :icon="'clock'"/>
                    </div>
                    <div class="single-select__item"
                         :id="`end-product-ingredient-configs-ignore-${index}`"
                         :class="getIngredientConfigurationStatus(endProduct.item.id, ingredientConfigurationTypes.ignoreCraftingTime)"
                         :title="__('ignoreFollowUpAndCraftingTimeExplanation')"
                         @click="updateIngredientConfiguration({id: endProduct.item.id, type: ingredientConfigurationTypes.ignoreCraftingTime})"
                    >
                      <icon class="single-select__item-icon" :icon="'list-point'"/>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="selected-item__required-industries"
                       :id="`ingredient-ingredient-industries-${index}`">
                    <div class="selected-item__required-industry selected-item__required-industry--small"
                         v-for="requiredIndustry in endProduct.requiredIndustries"
                         :style="{'background-image': `url(${requiredIndustry.icon})`}"
                         :title="requiredIndustry.name"
                         :class="getTierBackgroundClass(requiredIndustry)"
                    >
                      <span class="selected-item__required-industry-name">{{
                          requiredIndustry.scale.toUpperCase()
                        }}</span>
                    </div>
                  </div>
                </td>
                <td class="editable-table__button" v-if="hasEditRights"
                    @click="deleteEndProduct(endProduct.craftingListItemId)" v-slashes>
                  <icon :icon="'bin'"/>
                </td>
              </tr>
            </table>
            <div class="add-crafting-list-item" v-if="hasEditRights">
              <div class="add-crafting-list-item__title">{{ __('addAnEndProduct') }}</div>
              <div class="layout">
                <div class="flex xs12 sm6 mt">
                  <select-reference
                      v-model="newCraftingListItem.itemId"
                      :endpoint="'/ingame/items/item'"
                      :field-title="'fullName'"
                      :nothing-selected-message="__('common.no_item_selected')"
                      :error-messages="errors.itemId"
                  />
                </div>
                <div class="flex xs12 sm3 mt">
                  <text-field
                      v-model="newCraftingListItem.amount"
                      :label="__('component.crafting_calculator.amount')"
                      :min="1"
                      :type="'number'"
                      :readonly="loading"
                      :error-messages="errors.amount"
                      @keyup:enter="addEndProduct"
                  />
                </div>
                <div class="flex xs12 sm3 mt">
                  <div class="btn btn--big btn--info"
                       :disabled="loading"
                       @click="addEndProduct"
                  >
                    <icon :icon="'add'"/>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <h2 v-else>{{ __('createCraftingListFirst') }}</h2>
        </div>
      </div>

      <div class="flex xs12 sm5 mt">
        <div class="tile">
          <ingredients
              v-if="!createMode"
              :ingredients="selectedNode ? selectedNode.recursiveIngredients : ingredients"
              :ingredient-configurations="ingredientConfigurations"
              :ingredient-configuration-types="ingredientConfigurationTypes"
              @updateIngredientConfiguration="updateIngredientConfiguration"
          />
          <h2 v-else>{{ __('createCraftingListFirst') }}</h2>
        </div>
      </div>
    </div>
    <edit-item-report ref="editItemReport" @created="sayThankYouForItemReport"/>
    <input id="copy_uri" :value="uriToCopy" readonly style="opacity: 0"/>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import AppHeader from "../AppHeader";
import ItemDataTable from './SelectedItem/DataTable';
import { localizationMixin } from '../../mixins/localization';
import apiSecured from "../../api/secured";
import { notificationsMixin } from "../../mixins/notifications";
import { userMixin } from "../../mixins/user";
import EditItemReport from "../Ingame/EditItemReport";
import PriceLogs from "../Ingame/Item/PriceLogs";
import EditCraftingList from "../Ingame/EditCraftingList";
import Ingredients from "./Ingredients";
import CraftingTree from "./CraftingTree";
import { displayTime, numberToLocaleString, renderWithTierAndSize } from "../../helpers/itemHelpers";

export default {
  components: { EditCraftingList, EditItemReport, AppHeader, ItemDataTable, PriceLogs, CraftingTree, Ingredients },
  mixins: [localizationMixin, notificationsMixin, userMixin],
  props: ['craftingListId'],
  data: () => ({
    amount: 1,
    craftingListIdNew: 'new',
    loading: false,
    craftingList: null,
    craftingListItems: [],
    endProducts: [],
    tree: null,
    recursiveOreBuyPrice: 0.0,
    recursiveOreSellPrice: 0.0,
    craftingTime: 0,
    ingredients: [],
    ignoreIngredientConfigurations: false,
    ingredientConfigurations: [],
    ingredientConfigurationTypes: {
      none: 0,
      ignoreFollowUp: 1,
      ignoreCraftingTime: 2
    },
    selectedNodeId: null,
    showTree: true,

    changedEndProductAmountIds: [],
    showEditCraftingList: false,
    newCraftingListItem: {
      itemId: null,
      amount: 1,
    },
    sortModes: {
      no: 'no',
      asc: 'asc',
      desc: 'desc',
    },
    sorting: 'no',
    errors: {
      itemId: [],
      amount: [],
    },
    owner: null,
  }),
  watch: {
    craftingListId() {
      this.resetCraftingResult();
      if (!this.createMode) {
        this.refresh();
      }
    },
  },
  computed: {
    selectedNode() {
      return this.getSelectedNode(this.selectedNodeId, this.tree);
    },
    uriToCopy() {
      return window.location;
    },
    createMode() {
      return this.craftingListId === this.craftingListIdNew
    },
    hasEditRights() {
      return (this.craftingList && this.craftingList.userId === this.$store.state.user.id) || this.craftingListId === this.craftingListIdNew;
    },
  },
  methods: {
    numberToLocaleString,
    renderWithTierAndSize,
    displayTime,

    getTierBackgroundClass(item) {
      return {
        'selected-item__required-industry--tier-1': item.tier === 1,
        'selected-item__required-industry--tier-2': item.tier === 2,
        'selected-item__required-industry--tier-3': item.tier === 3,
        'selected-item__required-industry--tier-4': item.tier === 4,
        'selected-item__required-industry--tier-5': item.tier === 5,
      }
    },
    toggleShowTree() {
      localStorage.setItem('showCraftingTree', this.showTree ? "1" : "0");
    },
    getSelectedNode(nodeId, node) {
      if(!node) {
        return null;
      }
      if (nodeId === node.id) {
        return node;
      }
      let matchedChildNode = null;
      node.children.forEach(child => {
        const childNode = this.getSelectedNode(nodeId, child);
        if (childNode !== null) {
          matchedChildNode = childNode;
        }
      })
      return matchedChildNode;
    },
    handleNodeClicked({ nodeId, itemId }) {
      this.selectedNodeId = nodeId;
      this.selectedNodeItemId = itemId;
    },

    deleteCraftingList() {
      this.$emit('delete', this.craftingList);
    },
    copyUri() {
      this.$nextTick()
          .then(() => {
            let copyText = document.getElementById("copy_uri");
            copyText.select();
            document.execCommand("copy");
            this.showSuccessNotification(this.__('uriCopied'));
          });
    },
    handleCraftingListCreated(craftingList) {
      this.$emit('created');
      this.craftingListIdChanged(craftingList);
    },
    craftingListIdChanged({ id }) {
      this.$emit('craftingListIdChanged', id);
    },
    isChangedEndProductAmount(endProduct) {
      return this.changedEndProductAmountIds.some(r => r === endProduct.craftingListItemId);
    },
    handleEndProductAmountChanged(endProduct) {
      if(this.loading) {
        return;
      }
      if (!this.isChangedEndProductAmount(endProduct)) {
        this.changedEndProductAmountIds.push(endProduct.craftingListItemId);
      }
    },
    showReportItem() {
      this.$refs.editItemReport.showCreate();
    },
    sayThankYouForItemReport() {
      this.refresh();
      this.$refs.editItemReport.close();
      Swal.fire({
        title: this.__('thankYou'),
        html: this.__('sayThankYouForOreAppearanceReport').replace('$user', this.$store.state.user.username),
        type: 'success',
      });
    },
    getIngredientConfigurationStatus(itemId, target) {
      let selectedIngredientConfigurations = this.ingredientConfigurations.filter(ic => ic.itemId === itemId);
      let selected = false;

      selected = selectedIngredientConfigurations.length === 0 && target === this.ingredientConfigurationTypes.none;

      if (selectedIngredientConfigurations.length > 0) {
        selected = selectedIngredientConfigurations.filter(ic => ic.type === target).length > 0;
      }

      return {
        'single-select__item--selected': selected
      }
    },
    emitEdit(item) {
      this.$emit('edit', item);
    },
    resetCraftingResult() {
      this.endProducts = [];
      this.recursiveOreBuyPrice = 0;
      this.recursiveOreSellPrice = 0;
      this.craftingTime = 0;
      this.ingredients = [];
    },
    async updateIngredientConfiguration({id, type}) {
      this.loading = true;
      await apiSecured.put('/ingame/items/ingredientconfiguration', {
        itemId: id,
        type
      })
          .then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.saved'));
            this.$emit('ingredientConfigurationUpdated', res.data);
            this.refresh();
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async refresh() {
      if (!this.craftingListId || this.loading || this.createMode) {
        return;
      }
      await this.refreshCraftingList();
      await this.refreshCraftingListItems();
      await this.refreshIngredientConfigurations();

      const params = {
        ignoreIngredientConfigurations: this.ignoreIngredientConfigurations,
        amount: this.amount
      };

      this.loading = true;
      await apiSecured.get('/ingame/items/craftingcalculator/crafting-list/' + this.craftingListId, { params })
          .then(async (res) => {
            this.resetCraftingResult();
            this.tree = res.data;
            this.tree.item = {
              id: this.craftingList.id,
              name: this.craftingList.name,
              minimumCraftingAmount: 1
            }
            this.endProducts = res.data.children.map(endProduct => {
              const craftingListItem = this.craftingListItems.find(cli => cli.itemId === endProduct.item.id)
              endProduct.actualAmount = craftingListItem.amount;
              endProduct.craftingListItemId = craftingListItem.id;
              return endProduct;
            });
            this.recursiveOreBuyPrice = res.data.recursiveOreBuyPrice;
            this.recursiveOreSellPrice = res.data.recursiveOreSellPrice;
            this.craftingTime = res.data.recursiveCraftingTime;
            this.ingredients = res.data.recursiveIngredients;
            this.loading = false;
            this.selectedNodeId = this.tree.id;
            this.showInfoNotification('Result loaded');
            this.$emit('itemLoaded', this.item);
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async refreshCraftingList() {
      if (!this.craftingListId || this.loading) {
        return;
      }

      this.loading = true;
      await apiSecured.get('/ingame/items/craftinglist/' + this.craftingListId)
          .then(async (res) => {
            this.craftingList = res.data;
            this.loading = false;
            this.showInfoNotification('Crafting list loaded');
            this.$emit('craftingListLoaded', this.craftingList);
            await this.getOwner();
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async refreshCraftingListItems() {
      if (!this.craftingListId || this.loading) {
        return;
      }

      this.loading = true;
      await apiSecured.get('/ingame/items/craftinglistitem/by-crafting-list/' + this.craftingListId)
          .then(async (res) => {
            this.craftingListItems = res.data;
            this.loading = false;
            this.showInfoNotification('Crafting list items loaded');
            this.$emit('craftingListItemsLoaded', this.endProducts);
            await this.getOwner();
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async refreshIngredientConfigurations() {
      this.loading = true;
      await apiSecured.get('/ingame/items/ingredientconfiguration/by-current-user')
          .then((res) => {
            this.ingredientConfigurations = res.data;
            this.loading = false;
            this.showInfoNotification('Ingredient configurations loaded');
            this.$emit('ingredientConfigurationsLoaded', this.ingredientConfigurations);
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async getOwner() {
      this.owner = null;
      this.loading = true;
      await apiSecured.get('/community/user/' + this.craftingList.userId)
          .then((res) => {
            this.owner = res.data;
            this.loading = false;
            this.showInfoNotification('Owner loaded');
            this.$emit('ownerLoaded', this.owner);
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    addEndProduct() {
      if(this.loading) {
        return;
      }
      this.errors = {
        itemId: [],
        amount: [],
      }
      if (!this.newCraftingListItem.itemId || this.newCraftingListItem.amount <= 0 || this.loading) {
        if (!this.newCraftingListItem.itemId) {
          this.errors.itemId.push(this.__('pleaseSelectItem'));
        }
        if (this.newCraftingListItem.amount <= 0) {
          this.errors.amount.push(this.__('amountTooSmall'));
        }
        return;
      }
      this.loading = true;
      let endpoint = '/ingame/items/craftinglistitem';
      const payload = {
        craftingListId: this.craftingListId,
        itemId: this.newCraftingListItem.itemId,
        amount: this.newCraftingListItem.amount
      }
      apiSecured.post(endpoint, payload).then((res) => {
        this.loading = false;
        this.showSuccessNotification(this.__('common.saved'));
        this.newCraftingListItem = {
          itemId: null,
          amount: 1
        }
        this.refresh();
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
        this.refresh();
      });
    },
    updateEndProductAmount(endProduct) {
      if(this.loading) {
        return;
      }
      if (!this.isChangedEndProductAmount(endProduct)) {
        return;
      }
      this.loading = true;
      let endpoint = '/ingame/items/craftinglistitem/' + endProduct.craftingListItemId;
      const payload = [];
      payload.push({
        path: '/amount',
        op: 'replace',
        value: endProduct.actualAmount
      })
      apiSecured.patch(endpoint, payload).then((res) => {
        this.loading = false;
        const changeIndex = this.changedEndProductAmountIds.findIndex(r => r === endProduct.craftingListItemId)
        if (changeIndex > -1) {
          this.changedEndProductAmountIds.splice(changeIndex, 1);
        }
        this.showSuccessNotification(this.__('common.saved'));
        this.refresh();
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
        this.refresh();
      });
    },
    deleteEndProduct(id) {
      if (this.loading) {
        return;
      }
      Swal.fire({
        title: this.__('common.are_you_sure'),
        text: this.__('questionDeleteEndProduct'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.__('common.yes_delete_it')
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let endpoint = '/ingame/items/craftinglistitem/' + id;
          apiSecured.delete(endpoint).then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.deleted'));
            this.refresh();
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.refresh();
          });
        }
      });
    },
    goBack() {
      this.$emit('goBack');
    },
  },
  created() {
    const storedShowTree = localStorage.getItem('showCraftingTree');
    if(["0", "1"].includes(storedShowTree)) {
      this.showTree = storedShowTree === "1";
    }
    this.refresh();
  }
}
</script>