<template>
  <div class="item-list">
    <div
        class="item-list__item"
        :class="{'item-list__item--hidden' : item.hidden, 'item-list__item--need-review': item.needReview}"
        v-for="item in items"
        @click="selectItem(item)"
        v-slashes
    >
      <img
          :src="item.icon" :alt="item.icon"
          class="item-list__item-icon"
      >
      <div class="item-list__item-meta skewed-ribbon skewed-ribbon--absolute">
        <div class="item-list__item-tier skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">{{ item.tier ? item.tier : '-' }}</div>
      </div>
      <div class="item-list__item-title" v-html="renderWithTierAndSize(item.name, item.scale, item.tier)"></div>
      <div class="item-list__actions">
        <div class="item-list__action"
             v-if="hasOneRoles(['InGame Items Editor'])"
             :title="__('common.edit')"
             @click="emitEdit(item, $event)"
        ><icon icon="pencil"></icon></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { localizationMixin } from "../../mixins/localization";
  import { userMixin } from "../../mixins/user";
  import { renderWithTierAndSize } from "../../helpers/itemHelpers";

  export default {
    mixins: [localizationMixin, userMixin],
    props: ['items'],
    methods: {
      renderWithTierAndSize,
      selectItem(item) {
        this.$emit('itemSelected', item);
      },
      emitEdit(item, $event) {
        $event.stopPropagation();
        this.$emit('edit', item);
      }
    }
  }
</script>