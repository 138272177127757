<template>
  <div>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="selected-item__section-title selected-item__section-title--flex">
      <span class="selected-item__section-title-text">
        {{ __('component.crafting_calculator.usages') }}
      </span>
      <span class="selected-item__section-title-action"
            :class="{'selected-item__section-title-action--flipped' : sorting === sortModes.desc}"
            @click="switchSorting()"
      >
        Sort
        <icon v-if="sorting !== sortModes.no" :icon="'triangle-up'"/>
        <icon v-if="sorting === sortModes.no" :icon="'list-point'"/>
      </span>
    </div>
    <div class="selected-item__group-filters">
      <div class="selected-item__group-filter"
           :title="`${__('showAll')}`"
           :class="{'selected-item__group-filter--active': usagesGroupId === null}"
           @click="setUsagesGroupId(null)"
      >
        <div class="selected-item__group-filter-icon"
             :style="{'background-image': `url(https://cdn.hyperion-corporation.de/ui/svgs/asterisk.svg)`}"
        ></div>
      </div>
      <div class="selected-item__group-filter"
           v-for="group in usagesGroups"
           :title="`${__('filterByGroup')}: ${group.name}`"
           :class="{'selected-item__group-filter--active': usagesGroupId === group.id}"
           @click="setUsagesGroupId(group.id)"
      >
        <div class="selected-item__group-filter-icon"
             :style="{'background-image': `url(${group.icon})`}"
        ></div>
      </div>
    </div>
    <table class="editable-table editable-table--in-tile mt">
      <tr
          v-for="usage in filteredUsages.filter(fUsage => !fUsage.isSub)"
          class="editable-table__row"
      >
        <td class="editable-table__icon"><img :src="usage.icon" alt="-"/></td>
        <td>
          <div class="skewed-ribbon skewed-ribbon--absolute-right">
            <div class="skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">{{
                usage.tier ? usage.tier
                    : '-'
              }}
            </div>
          </div>
          <span v-html="renderWithTierAndSize(usage.name, usage.scale, usage.tier)"></span>
        </td>
      </tr>
      <tr class="editable-table__row editable-table__row--seperator">
        <td colspan="2"></td>
      </tr>
      <tr
          v-for="usage in filteredUsages.filter(fUsage => fUsage.isSub)"
          class="editable-table__row"
      >
        <td class="editable-table__icon"><img :src="usage.icon" alt="-"/></td>
        <td>
          <div class="skewed-ribbon skewed-ribbon--absolute-right">
            <div class="skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">{{
                usage.tier ? usage.tier
                    : '-'
              }}
            </div>
          </div>
          <span v-html="renderWithTierAndSize(usage.name, usage.scale, usage.tier)"></span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { localizationMixin } from '../../mixins/localization';
import apiSecured from "../../api/secured";
import { notificationsMixin } from "../../mixins/notifications";
import { userMixin } from "../../mixins/user";
import { renderWithTierAndSize } from "../../helpers/itemHelpers";
import * as axios from "axios";

export default {
  mixins: [localizationMixin, notificationsMixin, userMixin],
  props: ['itemId'],
  data: () => ({
    loading: false,
    usagesGroups: [],
    usagesGroupId: null,
    usages: [],
    sortModes: {
      no: 'no',
      asc: 'asc',
      desc: 'desc',
    },
    sorting: 'no',
    cancelSource: null,
  }),
  watch: {
    itemId() {
      this.refresh();
    },
  },
  computed: {
    filteredUsages() {
      let usages = [];
      if (this.usagesGroupId === null) {
        usages = Object.keys(this.usages).map(key => this.usages[key])
      } else {
        usages = Object.keys(this.usages).map(key => this.usages[key]).filter(usage => usage.groupId === this.usagesGroupId);
      }
      usages = usages.sort((a, b) => a.name > b.name ? 1 : -1);

      return usages;
    }
  },
  methods: {
    renderWithTierAndSize,
    switchSorting() {
      switch (this.sorting) {
        case this.sortModes.no:
          this.sorting = this.sortModes.asc;
          break;

        case this.sortModes.asc:
          this.sorting = this.sortModes.desc;
          break;

        default:
          this.sorting = this.sortModes.no;
      }
      localStorage.setItem('usagesSorting', this.sorting);
    },
    setUsagesGroupId(id) {
      this.usagesGroupId = id;
    },
    async refresh() {
      if(this.cancelSource !== null) {
        this.loading = false;
        await this.cancelSource.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.cancelSource = CancelToken.source();
      this.loading = true;
      await apiSecured.get('/ingame/items/craftingcalculator/' + this.itemId + '/usages',{
        cancelToken: this.cancelSource.token
      })
          .then(async (res) => {
            this.usages = res.data;
            this.loading = false;
            await this.refreshUsagesGroups();
            this.cancelSource = null;
          })
          .catch((error) => {
            this.loading = false;
            if (!axios.isCancel(error)) {
              this.showErrorNotifications(error);
            }
            this.cancelSource = null;
          });
    },
    async refreshUsagesGroups() {
      this.usagesGroups = [];
      const groupIds = Object.keys(this.usages).map(id => this.usages[id].groupId);
      if (groupIds.length === 0) {
        return;
      }
      this.loading = true;
      await apiSecured.get(`/ingame/items/group/(${[...new Set(groupIds)].join(',')})`)
          .then((res) => {
            this.usagesGroups = res.data.sort((a, b) => a.sorting - b.sorting);
            this.loading = false;
            this.showInfoNotification('Usages groups loaded');
            this.$emit('usagesGroupsLoaded', this.usagesGroups);
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
  },
  created() {
    const savedSorting = localStorage.getItem('usagesSorting');
    if (Object.values(this.sortModes).includes(savedSorting)) {
      this.sorting = savedSorting;
    }
    if(this.itemId) {
      this.refresh();
    }
  }
}
</script>