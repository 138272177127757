<template>
  <div class="price-logs">
    <link rel="stylesheet" type="text/css" href="path/to/chartjs/dist/Chart.min.css">
    <div class="price-logs__empty" v-if="priceLogs.length === 0">
      <div class="price-logs__empty-line"></div>
      <div class="price-logs__empty-label">no price logs currently</div>
      <div class="price-logs__empty-line"></div>
    </div>
    <div :id="priceChartId">
    </div>
  </div>
</template>

<script>
  import Highstocks from 'highcharts/highstock';
  import theme from '../../../library/highcharts/theme';
  import apiSecured from '../../../api/secured';
  import {localizationMixin} from "../../../mixins/localization";
  import {notificationsMixin} from "../../../mixins/notifications";

  Highstocks.setOptions(theme);

  const CHART_HEIGHT = 300;
  const GRADIENT_COORDS = {
    x1: 0, x2: 0, y1: 0, y2: 1,
  };
  const COLOR_GREEN = {
    r: 7,
    g: 150,
    b: 58,
    rgba(alpha = 1.0) {
      return `rgba(${this.r},${this.g},${this.b},${alpha})`;
    },
  };
  const COLOR_BLUE = {
    r: 33,
    g: 122,
    b: 206,
    rgba(alpha = 1.0) {
      return `rgba(${this.r},${this.g},${this.b},${alpha})`;
    },
  };
  const COLOR_YELLOW = {
    r: 205,
    g: 206,
    b: 6,
    rgba(alpha = 1.0) {
      return `rgba(${this.r},${this.g},${this.b},${alpha})`;
    },
  };

  export default {
    mixins: [localizationMixin, notificationsMixin],
    name: "PriceLogs",
    props: ['itemId'],
    data: () => ({
      loading: false,
      priceLogs: []
    }),
    computed: {
      priceChartId() {
        return 'chart_price_' + (this.itemId ? this.itemId : 'none');
      },
    },
    watch: {
      itemId() {
        this.refresh();
      }
    },
    methods: {
      rerenderCharts() {
        const sellPriceLogs = this.priceLogs.filter(priceLog => priceLog.field === 'sellPrice');
        const buyPriceLogs = this.priceLogs.filter(priceLog => priceLog.field === 'buyPrice');
        const botPriceLogs = this.priceLogs.filter(priceLog => priceLog.field === 'botPrice');
        const sellPriceChartValues = sellPriceLogs.map(priceLog => {
          return [new Date(priceLog.createdAt).getTime(), priceLog.price];
        });
        const buyPriceChartValues = buyPriceLogs.map(priceLog => {
          return [new Date(priceLog.createdAt).getTime(), priceLog.price];
        });
        const botPriceChartValues = botPriceLogs.map(priceLog => {
          return [new Date(priceLog.createdAt).getTime(), priceLog.price];
        });
        const fillColorSellPrice = {
          linearGradient: GRADIENT_COORDS,
          stops: [
            [0, COLOR_GREEN.rgba(0.5)],
            [1, COLOR_GREEN.rgba(0.0)],
          ],
        };
        const fillColorBuyPrice = {
          linearGradient: GRADIENT_COORDS,
          stops: [
            [0, COLOR_BLUE.rgba(0.5)],
            [1, COLOR_BLUE.rgba(0.0)],
          ],
        };
        const fillColorBotPrice = {
          linearGradient: GRADIENT_COORDS,
          stops: [
            [0, COLOR_YELLOW.rgba(0.5)],
            [1, COLOR_YELLOW.rgba(0.0)],
          ],
        };

        let seriesOptions = [
          {
            type: 'line',
            name: this.__('entity.ingame.item.sell_price'),
            data: sellPriceChartValues,
            color: COLOR_GREEN.rgba(),
            fillColor: fillColorSellPrice,
          },
          {
            type: 'line',
            name: this.__('entity.ingame.item.buy_price'),
            data: buyPriceChartValues,
            color: COLOR_BLUE.rgba(),
            fillColor: fillColorBuyPrice,
          },
          {
            type: 'line',
            name: this.__('entity.ingame.item.bot_price'),
            data: botPriceChartValues,
            color: COLOR_YELLOW.rgba(),
            fillColor: fillColorBotPrice,
          },
        ];

        Highstocks.stockChart(this.priceChartId, {
          credits: {
            enabled: false,
          },
          chart: {
            height: CHART_HEIGHT,
          },
          legend: {
            enabled: true
          },
          plotOptions: {
            series: {
              showInNavigator: true,
            }
          },
          tooltip: {
            xDateFormat: '%Y-%m-%d %H:%M:%S',
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
            valueDecimals: 2,
            split: false
          },
          rangeSelector: {
            allButtonsEnabled: true,
            selected: 4,
          },
          series: seriesOptions
        });
      },
      async refresh() {
        if (!this.itemId) {
          return;
        }
        this.loading = true;
        await apiSecured.get('/ingame/items/pricelog/by-item/' + this.itemId).then((res) => {
          this.priceLogs = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.price_logs_loaded'));
          if(this.priceLogs.length > 0) {
            this.rerenderCharts();
          }
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
    },
    created() {
      this.refresh();
    }
  }
</script>