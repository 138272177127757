<template>
  <div class="edit-crafting-list">
    <progress-linear v-if="loading"/>
    <div class="layout layout--wrap" v-if="record">
      <div class="flex xs12">
        <div class="layout layout--wrap">
          <div class="flex xs12">
            <text-field
                v-model="record.name"
                :label="__('entity.common.name')"
                :error-messages="errors.name"
                maxlength="50"
                :prepend-icon="isChanged('name') ? 'pencil' : null"
                @input="registerChange('name')"
            />
          </div>
          <div class="flex xs12">
            <ckeditor v-model="record.description"
                      :title="__('entity.common.description')"
                      :editor="editor"
                      :config="editorConfig"
                      @input="registerChange('description')"></ckeditor>
          </div>
          <div class="flex xs12 sm6 mt">
            <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                 @click="update" v-slashes>{{ __('save') }}
            </div>
          </div>
          <div class="flex xs12 sm6 mt">
            <div class="btn" :loading="loading" :disabled="loading"
                 @click="close" v-slashes>{{ __('common.close') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import { editFormMixin } from "../../mixins/editForm";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

  export default {
    components: {},
    mixins: [localizationMixin, editFormMixin],
    props: ['craftingListId'],
    watch: {
      craftingListId() {
        if (this.craftingListId) {
          this.showEdit({ id: this.craftingListId })
        } else {
          this.showCreate();
        }
      }
    },
    data: () => ({
      editor: ClassicEditor,
      endpoint: '/ingame/items/craftinglist',
      emptyRecord: {
        id: null,
        name: '',
        description: null,
        createdAt: '---',
        updatedAt: '---'
      },
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ],
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
          ]
        }
      },
    }),
    created() {
      if (this.craftingListId) {
        this.showEdit({ id: this.craftingListId })
      } else {
        this.showCreate();
      }
    }
  }
</script>
