<template>
  <div class="side-nav tile">
    <v-jstree
        v-if="groupsTree.length > 0"
        :data="groupsTree"
        :size="'large'"
        :text-field-name="'name'"
        :value-field-name="'id'"
        :children-field-name="'children'"
        @item-click="itemClicked"
    >
      <template scope="_">
        <div style="display: inherit; width: 200px">
          <img :src="_.model.icon">
          {{_.model.name}}
        </div>
      </template>
    </v-jstree>
  </div>
</template>

<script>
  import VJstree from 'vue-jstree';
  import { rerenderMixin } from "../../mixins/rerender";
  import apiSecured from "../../api/secured";
  import { notificationsMixin } from "../../mixins/notifications";

  export default {
    components: {
      VJstree
    },
    mixins: [rerenderMixin, notificationsMixin],
    data: () => ({
      rerenderKey: 'no-set',
      expanded: [],
      groupsTree: [],
      selectedGroup: null,
    }),
    methods: {
      deselectGroup() {
        if (this.selectedGroup !== null)
          this.selectedGroup.selected = false;

        this.selectedGroup = null;
      },
      itemClicked(vm, model, event) {
        this.selectedGroup = model;
        this.selectGroup(model);
      },
      selectGroup(group) {
        this.$emit('groupSelected', group);
      },
      buildGroupsTree() {
        return this.groups.filter(group => group.parentId === null).map(group => {
          group.children = this.getGroupChildren(group.id);
          return group;
        });
      },
      getGroupChildren(parentId) {
        return this.groups.filter(group => group.parentId === parentId).map(group => {
          group.children = this.getGroupChildren(group.id);
          return group;
        });
      },
      async refreshGroups() {
        this.loading = true;
        await apiSecured.get('/ingame/items/group', {
          params: {
            pageSize: 10000,
            fields: 'id,name,icon,parentId',
            orderBy: 'name'
          }
        })
          .then((res) => {
            this.groups = res.data;
            this.groupsTree = this.buildGroupsTree();
            this.loading = false;
            this.showInfoNotification('Groups loaded');
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
      },
    },
    created() {
      this.refreshGroups();
    }
  }
</script>