<template>
  <div class="selected-item view" v-if="selectedItemId">
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__buttons">
        <div class="control-bar__button"
             v-if="hasOneRoles(['InGame Items Editor'])"
             :title="__('common.edit')" @click="emitEdit(item)" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/pencil.svg"
               alt="edit"/>
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.navigate_up')"
             @click="goBack"
             v-slashes
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/chevron-left.svg"
               alt="back"/>
        </div>
        <!--        <div class="control-bar__button" :title="'Tour'" @click="startTour" v-slashes>-->
        <!--          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/tour.svg"-->
        <!--               alt="report"/>-->
        <!--        </div>-->
        <div class="control-bar__button" :title="__('reportItem')" @click="showReportItem" v-slashes id="report">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/report.svg"
               alt="report"/>
        </div>
      </div>
    </div>
    <div class="layout layout--wrap">
      <div class="flex xs12 md6 lg3 mt">
        <div class="tile" id="basic-info">
          <div class="layout layout--wrap">
            <div class="flex xs12 sm4">
              <img class="selected-item__icon" :src="item.icon" alt="#"/>
            </div>
            <div class="flex xs12 sm8 mt">
              <div class="selected-item__title" v-html="renderWithTierAndSize(item.name, item.scale, item.tier)"></div>
            </div>
            <div class="flex xs12">
              <div class="selected-item__description mt">{{ item.description ? item.description : 'no-description' }}
              </div>
              <div class="selected-item__subdescription mt">{{
                  item.subdescription ? item.subdescription :
                      'no-subdescription'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex xs12 md6 lg3 mt">
        <div class="tile">
          <div class="selected-item__title">{{ __('totals') }}</div>
          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6 selected-item__primary-result-label">
              {{ __('craftingTime') }}
            </div>
            <div class="flex xs12 sm6 selected-item__primary-result-value">{{ displayTime(craftingTime) }}</div>
          </div>
          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6 selected-item__primary-result-label">
              {{ __('linearCraftingTime') }}
            </div>
            <div class="flex xs12 sm6 selected-item__primary-result-value">{{ displayTime(linearCraftingTime) }}</div>
          </div>
          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6 selected-item__primary-result-label">
              {{ __('oreBuyValue') }}
            </div>
            <div class="flex xs12 sm6 selected-item__primary-result-value"
                 v-html="numberToLocaleString(recursiveOreBuyPrice)">
            </div>
          </div>
          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6 selected-item__primary-result-label">
              {{ __('oreSellValue') }}
            </div>
            <div class="flex xs12 sm6 selected-item__primary-result-value"
                 v-html="numberToLocaleString(recursiveOreSellPrice)">
            </div>
          </div>
          <div class="layout layout--wrap mt" v-if="hasOneRoles(['Member']) && item.vQuantaPrice">
            <div class="flex xs12 sm6 selected-item__primary-result-label">
              {{ __('vQuantaPriceTotal') }}
            </div>
            <div class="flex xs12 sm6 selected-item__primary-result-value"
                 v-html="numberToLocaleString(item.vQuantaPrice * amount)">
            </div>
          </div>
          <div class="layout layout--wrap mt">
            <div class="flex xs12 sm6 selected-item__primary-result-label">
              {{ __('requiredIndustries') }}
            </div>
            <div class="flex xs12 sm6 selected-item__primary-result-value">
              <div class="selected-item__required-industries">
                <div class="selected-item__required-industry"
                     v-for="requiredIndustry in requiredIndustries"
                     :style="{'background-image': `url(${requiredIndustry.icon})`}"
                     :title="requiredIndustry.fullName"
                     :class="getTierBackgroundClass(requiredIndustry)"
                >
                  <span class="selected-item__required-industry-name">{{
                      requiredIndustry.scale.toUpperCase()
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="layout layout--wrap mt">
            <div class="flex xs6 mt">
              <text-field
                  v-model="industriesAmount"
                  :label="__('endIndustriesAmount')"
                  :min="1"
                  :type="'number'"
                  :readonly="loading"
                  :hide-details="true"
                  @keyup:enter="refresh"
              />
            </div>
            <div class="flex xs6 mt">
              <text-field
                  v-model="amount"
                  :label="__('component.crafting_calculator.amount')"
                  :min="1"
                  :type="'number'"
                  :readonly="loading"
                  :hide-details="true"
                  @keyup:enter="refresh"
              />
            </div>
            <div class="flex xs12 mt">
              <div class="btn btn--info btn--big" :loading="loading" :disabled="loading" id="calculate"
                   @click="refresh" v-slashes>{{ __('component.crafting_calculator.calculate') }}
              </div>
            </div>
            <div class="flex xs12 mt">
              <my-switch
                  v-model="ignoreIngredientConfigurations"
                  :label="__('ignoreIngredientConfigurations')"
                  :disabled="loading"
                  @input="refresh"
              ></my-switch>
            </div>
            <div class="flex xs12 mt">
              <my-switch
                  v-model="showTree"
                  :label="__('showCraftingTree')"
                  :disabled="loading"
                  @input="toggleShowTree"
              ></my-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="flex xs12 lg6 mt">
        <div class="tile">
          <price-logs :item-id="item ? item.id : null"/>
        </div>
      </div>

      <div class="flex xs12 mt" v-if="showTree">
        <crafting-tree :tree="tree" :node-id="selectedNodeId" @nodeSelected="handleNodeClicked"/>
      </div>

      <div class="flex xs12 lg3 mt">
        <div class="tile" id="data-sheet">
          <div class="selected-item__section-title">{{ __('component.crafting_calculator.item_data') }}</div>
          <item-data-table class="mt" :item="selectedNode.item"
                           v-if="selectedNode" :group="group"/>
        </div>
      </div>
      <div class="flex xs12 lg6 mt">
        <div class="tile">
          <ingredients :ingredients="selectedNode ? selectedNode.recursiveIngredients : ingredients"
                       :ingredient-configurations="ingredientConfigurations"
                       :ingredient-configuration-types="ingredientConfigurationTypes"
                       @updateIngredientConfiguration="updateIngredientConfiguration"
          />
        </div>
      </div>
      <div class="flex xs12 lg3 mt">
        <div class="tile">
          <usages :item-id="selectedNodeItemId"/>
        </div>
      </div>
    </div>
    <edit-item-report ref="editItemReport" @created="sayThankYouForItemReport"/>
  </div>
</template>

<script>
import PriceLogs from '../Ingame/Item/PriceLogs';
import AppHeader from "../AppHeader";
import ItemDataTable from './SelectedItem/DataTable';
import { localizationMixin } from '../../mixins/localization';
import apiSecured from "../../api/secured";
import { notificationsMixin } from "../../mixins/notifications";
import { userMixin } from "../../mixins/user";
import EditItemReport from "../Ingame/EditItemReport";
import Swal from "sweetalert2";
import Ingredients from "./Ingredients";
import CraftingTree from "./CraftingTree";
import Usages from "./Usages";
import {
  displayTime,
  numberToLocaleString,
  renderWithTierAndSize
} from "../../helpers/itemHelpers";

export default {
  components: { CraftingTree, Usages, Ingredients, EditItemReport, PriceLogs, AppHeader, ItemDataTable },
  mixins: [localizationMixin, notificationsMixin, userMixin],
  props: ['selectedItemId'],
  data: () => ({
    group: null,
    loading: false,
    amount: 1,
    industriesAmount: 1,
    item: {},
    tree: null,
    recursiveOreBuyPrice: 0.0,
    recursiveOreSellPrice: 0.0,
    craftingTime: 0,
    linearCraftingTime: 0,
    ingredients: [],
    usages: [],
    requiredIndustries: [],
    ignoreIngredientConfigurations: false,
    ingredientConfigurations: [],
    ingredientConfigurationTypes: {
      none: 0,
      ignoreFollowUp: 1,
      ignoreCraftingTime: 2
    },
    selectedNodeId: null,
    selectedNodeItemId: null,
    showTree: true,
  }),
  watch: {
    selectedItemId() {
      this.resetCraftingResult();
      this.refresh();
      this.selectedNodeItemId = this.selectedItemId;
    },
    selectedNodeId() {
      this.refreshGroup(this.selectedNode.item.groupId);
    }
  },
  computed: {
    selectedNode() {
      return this.getSelectedNode(this.selectedNodeId, this.tree);
    }
  },
  methods: {
    numberToLocaleString,
    renderWithTierAndSize,
    displayTime: displayTime,
    getTierBackgroundClass(item) {
      return {
        'selected-item__required-industry--tier-1': item.tier === 1,
        'selected-item__required-industry--tier-2': item.tier === 2,
        'selected-item__required-industry--tier-3': item.tier === 3,
        'selected-item__required-industry--tier-4': item.tier === 4,
        'selected-item__required-industry--tier-5': item.tier === 5,
      }
    },
    toggleShowTree() {
      localStorage.setItem('showCraftingTree', this.showTree ? "1" : "0");
    },
    getSelectedNode(nodeId, node) {
      if(!node) {
        return null;
      }
      if (nodeId === node.id) {
        return node;
      }
      let matchedChildNode = null;
      node.children.forEach(child => {
        const childNode = this.getSelectedNode(nodeId, child);
        if (childNode !== null) {
          matchedChildNode = childNode;
        }
      })
      return matchedChildNode;
    },
    handleNodeClicked({ nodeId, itemId }) {
      this.selectedNodeId = nodeId;
      this.selectedNodeItemId = itemId;
    },
    showReportItem() {
      this.$refs.editItemReport.showCreate({ itemId: this.selectedItemId });
    },
    sayThankYouForItemReport() {
      this.refresh();
      this.$refs.editItemReport.close();
      Swal.fire({
        title: this.__('thankYou'),
        html: this.__('sayThankYouForOreAppearanceReport').replace('$user', this.$store.state.user.username),
        type: 'success',
      });
    },
    emitEdit(item) {
      this.$emit('edit', item);
    },
    emitAmountChange() {
      this.$emit('amountChanged', this.amount);
    },
    resetCraftingResult() {
      this.tree = null;
      this.item = {};
      this.amount = 0;
      this.recursiveOreBuyPrice = 0;
      this.recursiveOreSellPrice = 0;
      this.craftingTime = 0;
      this.ingredients = [];
      this.usages = [];
      this.requiredItemsForCrafting = [];
    },
    async updateIngredientConfiguration({ id, type }) {
      this.loading = true;
      await apiSecured.put('/ingame/items/ingredientconfiguration', {
        itemId: id,
        type
      })
          .then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.saved'));
            this.$emit('ingredientConfigurationUpdated', res.data);
            this.refresh();
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async refresh() {
      if (!this.selectedItemId || this.loading) {
        return;
      }

      await this.refreshIngredientConfigurations();

      const params = {
        amount: this.amount,
        industriesAmount: this.industriesAmount,
        ignoreIngredientConfigurations: this.ignoreIngredientConfigurations,
      };

      this.loading = true;
      await apiSecured.get('/ingame/items/craftingcalculator/' + this.selectedItemId, { params })
          .then(async (res) => {
            this.resetCraftingResult();
            await this.refreshGroup(res.data.item.groupId);
            this.tree = res.data;
            this.item = res.data.item;
            this.amount = res.data.amount;
            this.recursiveOreBuyPrice = res.data.recursiveOreBuyPrice;
            this.recursiveOreSellPrice = res.data.recursiveOreSellPrice;
            this.craftingTime = res.data.recursiveCraftingTime;
            this.linearCraftingTime = res.data.recursiveLinearCraftingTime;
            this.ingredients = res.data.recursiveIngredients;
            this.requiredIndustries = res.data.requiredIndustries;
            this.loading = false;
            this.selectedNodeId = this.tree.id;
            this.selectedNodeItemId = this.tree.item.id;
            this.showInfoNotification('Result loaded');
            this.$emit('itemLoaded', this.item);
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async refreshIngredientConfigurations() {
      this.loading = true;
      await apiSecured.get('/ingame/items/ingredientconfiguration/by-current-user')
          .then((res) => {
            this.ingredientConfigurations = res.data;
            this.loading = false;
            this.showInfoNotification('Ingredient configurations loaded');
            this.$emit('ingredientConfigurationsLoaded', this.ingredientConfigurations);
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    async refreshGroup(id) {
      this.loading = true;
      await apiSecured.get('/ingame/items/group/' + id)
          .then((res) => {
            this.group = res.data;
            this.loading = false;
            this.showInfoNotification('Group loaded');
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
    goBack() {
      this.$emit('goBack');
    },
  },
  created() {
    const storedShowTree = localStorage.getItem('showCraftingTree');
    if(["0", "1"].includes(storedShowTree)) {
      this.showTree = storedShowTree === "1";
    }
    this.refresh();
  }
}
</script>